.square-btn {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  line-height: 95%;
  color: $gray-dark1;

  border: 1px solid rgba($gray-dark1, 0.32);
  border-radius: 10px;

  transition:
    color 0.3s ease-in,
    background-color 0.3s ease-in,
    border-color 0.3s ease-in;

  @include box(54px);

  @include hover {
    &:hover {
      color: $white;
      background-color: $red;
      border-color: $red;
    }
  }

  @include media(desktop) {
    font-size: 14px;

    @include box(45px);
  }

  @include media(ipad-pro) {
    font-size: 15px;

    @include box(50px);
  }

  @include media(ipad) {
    font-size: 14px;

    @include box(54px);
  }

  &__text-container {
    text-align: left;
  }

  &__text {
    display: block;
  }

  &--active {
    color: $white;
    background-color: $red;
    box-shadow:
      0 2px 6px crgba(218, 43, 62, 0.12),
      0 0 1px $black-opacity4;
  }
}
