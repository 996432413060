.custom-popup-backdrop {
  background: $black-opacity85;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: transparent !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.mat-mdc-dialog-content {
  margin: 0 !important;
  padding: 0 !important;
  font-family: $golos !important;
}

.mat-mdc-dialog-actions {
  min-height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
}

.popup {
  width: 848px;
  max-width: 848px;
  max-height: 100% !important;
  font-family: $golos !important;

  @include media(desktop) {
    width: 624px;
    max-width: 624px;
  }

  @include media(ipad-pro) {
    width: 100%;
    max-width: none;
  }

  &__header-box {
    @include media(ipad) {
      flex-direction: column !important;
      align-items: flex-start !important;
      justify-content: flex-start !important;
    }
  }

  &__top-container {
    padding: 56px 72px 0;

    @include media(desktop) {
      padding: 48px 48px 0;
    }

    @include media(ipad-pro) {
      padding: 32px 32px 0;
    }

    @include less-than(500px) {
      padding: 20px 20px 0;
    }
  }

  &__middle-container {
    padding: 0 72px;

    @include media(desktop) {
      padding: 0 48px;
    }

    @include media(ipad-pro) {
      padding: 0 32px;
    }

    @include less-than(500px) {
      padding: 0 20px;
    }
  }

  &__bottom-container {
    padding: 0 72px 56px;

    @include media(desktop) {
      padding: 0 48px 48px;
    }

    @include media(ipad-pro) {
      padding: 0 32px 32px;
    }

    @include less-than(500px) {
      padding: 0 20px 20px;
    }
  }

  &__close-btn {
    margin: 0 auto 24px;
  }

  &__header {
    position: relative;
    height: 368px;

    @include media(desktop) {
      height: 328px;
    }

    @include media(ipad-pro) {
      height: 373px;
    }

    @include less-than(500px) {
      height: 200px;
    }
  }

  &__header-container {
    padding-bottom: 40px;

    @include box(100%);

    @include media(desktop) {
      padding-bottom: 32px;
    }

    @include media(ipad-pro) {
      padding-bottom: 40px;
    }

    @include less-than(500px) {
      padding-bottom: 40px;
    }
  }

  &__header-bg {
    position: absolute;
    object-fit: cover;

    @include box(100%);
  }

  &__header-title {
    position: relative;

    margin: 14px 0 0;

    font-size: 50px;
    font-weight: 700;
    line-height: 100%;
    color: $white;

    @include media(desktop) {
      font-size: 40px;
    }

    @include media(ipad-pro) {
      margin: 16px 0 0;
      font-size: 44px;
    }

    @include less-than(500px) {
      margin: 12px 0 0;
      font-size: 36px;
    }
  }

  &__container {
    padding-top: 40px;
    background-color: $gray-light3;

    @include media(desktop) {
      padding-top: 32px;
    }

    @include media(ipad-pro) {
      padding-top: 40px;
    }

    @include less-than(500px) {
      padding-top: 32px;
    }
  }

  &__text {
    font-size: 20px;
    line-height: 130%;
    color: $gray-dark1;
    white-space: pre-line;

    @include media(desktop) {
      font-size: 18px;
    }

    @include media(ipad-pro) {
      font-size: 20px;
      line-height: 22px;
    }

    @include less-than(500px) {
      font-size: 18px;
    }
  }

  &__back-btn,
  &__header-back-btn {
    display: none;

    @include media(ipad-pro) {
      display: inline-block;
    }
  }

  &__back-btn {
    @include media(ipad-pro) {
      margin-bottom: 42px;
    }

    @include less-than(500px) {
      margin-bottom: 34px;
    }
  }

  &__back-icon {
    margin-right: 12px;
  }

  &__registration-btn {
    position: relative;

    @include media(ipad) {
      margin-top: 15px;
    }
  }

  &__badge:not(:last-child) {
    margin-right: 6px;
  }
}

.restore-popup {
  width: 100%;
  max-width: 596px;
  max-height: 100% !important;

  @include media(desktop) {
    max-width: 578px;
  }

  @include media(ipad-pro) {
    max-width: 564px;
  }

  @include media(ipad) {
    max-width: none;
  }
}

.order-renew-popup {
  width: 100%;
  max-width: 596px;
  max-height: 100% !important;

  @include media(desktop) {
    max-width: 578px;
  }

  @include media(ipad-pro) {
    max-width: 564px;
  }
}

.order-renew-panel {
  .mat-mdc-dialog-actions {
    display: none;
  }

  .mat-mdc-dialog-content {
    @include media(ipad-pro) {
      border-radius: 24px;
    }
  }
}

.social-networks-popup {
  width: 100%;
  max-width: 930px;
}

.social-networks-popup-panel {
  overflow-y: auto;
  max-width: none !important;

  @include hide-scrollbar;

  @include media(ipad-pro) {
    max-width: 85% !important;
  }

  .mat-mdc-dialog-container {
    @include media(ipad-pro) {
      position: relative;
      max-width: none;
      padding: 30px 0 !important;
    }
  }

  .mat-mdc-dialog-content {
    max-height: none !important;

    @include media(ipad-pro) {
      max-height: 100% !important;
      margin: 0 auto !important;
      border-radius: 44px;
    }
  }
}

.order-timer-popup {
  width: 100%;
  max-width: 464px;
  max-height: 100% !important;

  @include media(desktop) {
    max-width: 376px;
  }

  @include media(ipad-pro) {
    max-width: 360px;
  }

  @include media(ipad) {
    max-width: none;
  }
}

.order-timer-panel {
  @include media(ipad) {
    max-width: none !important;
    margin-top: auto;

    @include box(100%);
  }

  .mat-mdc-dialog-container {
    @include media(ipad) {
      padding: 0 !important;
      border-radius: 0;
    }
  }

  .mat-mdc-dialog-content {
    border-radius: 24px;

    @include media(ipad) {
      height: 100% !important;
      max-height: 100% !important;
      border-radius: 0;
    }
  }

  .mat-mdc-dialog-actions {
    display: flex !important;

    @include media(ipad) {
      display: none !important;
    }
  }
}

.restore-ticket-panel {
  @include media(ipad) {
    max-width: none !important;
    margin-top: auto;

    @include box(100%);

    .mat-mdc-dialog-container {
      padding: 0 !important;
      border-radius: 0;
    }

    .mat-mdc-dialog-content {
      height: 100% !important;
      max-height: 100% !important;
      border-radius: 0;
    }
  }

  .mat-mdc-dialog-actions {
    display: flex !important;

    @include media(ipad) {
      display: none !important;
    }
  }

  .mat-mdc-dialog-content {
    border-radius: 24px;

    @include media(ipad) {
      border-radius: 0;
    }
  }
}

.popup-for-detail-page {
  height: 100%;

  @include media(ipad-pro) {
    max-width: none !important;
    margin-top: auto;

    @include box(100%);

    .mat-mdc-dialog-container {
      padding: 0 !important;
    }
  }

  .mat-mdc-dialog-surface {
    padding: 20px 0 !important;

    @include hide-scrollbar;

    @include media(ipad-pro) {
      padding: 0 !important;
    }
  }

  .mat-mdc-dialog-container {
    border-radius: 0;

    @include hide-scrollbar;
  }

  .mat-mdc-dialog-content {
    height: 100% !important;
    max-height: 100% !important;

    @include hide-scrollbar;
  }
}

.video-popup {
  position: relative;

  min-width: 1008px;
  min-height: 670px;
  max-height: none !important;

  text-align: center;

  @include media(desktop) {
    min-width: 752px;
    min-height: 500px;
  }

  @include media(ipad-pro) {
    min-width: 100%;
    min-height: 432px;
  }

  @include less-than(500px) {
    min-height: 250px;
  }
}

.video-popup-panel {
  @include media(ipad-pro) {
    width: 100%;
    max-width: none !important;
  }

  .mat-mdc-dialog-content {
    border-radius: 8px;

    @include media(desktop) {
      border-radius: 12px;
    }

    @include media(ipad-pro) {
      border-radius: 0;
    }
  }
}

.restore-spinner {
  position: absolute !important;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);

  width: 30px !important;
  height: 30px !important;

  svg {
    width: 30px !important;
    height: 30px !important;
  }
}

.mat-mdc-dialog-component-host {
  display: block !important;
}
