.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: $gray-light4 !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-floating-label--float-above {
  color: $gray-dark1 !important;
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-floating-label {
  color: $red !important;
}

.mdc-notched-outline__notch {
  max-width: calc(100% - 56px) !important;
}

.mdc-notched-outline__leading,
.mdc-notched-outline__notch,
.mdc-notched-outline__trailing,
.mat-mdc-text-field-wrapper {
  transition: border-color 0.3s ease-in;
}

.mdc-notched-outline__leading {
  width: 28px !important;
  min-width: 28px !important;
  border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important;
}

.mdc-notched-outline__trailing {
  width: 28px !important;
  min-width: 28px !important;
  border-top-right-radius: 100px !important;
  border-bottom-right-radius: 100px !important;
}

.mat-mdc-form-field-type-mat-select .mdc-notched-outline__leading {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}

.mat-mdc-form-field-type-mat-select .mdc-notched-outline__trailing {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.mat-mdc-form-field.pirexpo-textarea {
  .mdc-notched-outline__leading {
    width: 28px !important;
    min-width: 28px !important;
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
  }

  .mdc-notched-outline__trailing {
    width: 28px !important;
    min-width: 28px !important;
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: $gray-light1 !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  border-color: $gray-dark3 !important;
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: $red !important;
}

.mdc-text-field--outlined:not(
    .mdc-text-field--disabled,
    .mdc-text-field--focused,
    .mdc-text-field--invalid
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(
    .mdc-text-field--disabled,
    .mdc-text-field--focused,
    .mdc-text-field--invalid
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(
    .mdc-text-field--disabled,
    .mdc-text-field--focused,
    .mdc-text-field--invalid
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-color: $gray-dark2 !important;
}

.mdc-text-field--outlined {
  padding-left: 32px !important;
}

.mdc-floating-label--required,
.mat-form-field-required-marker.mat-mdc-form-field-required-marker {
  color: $red;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-text-field__input {
  color: $gray-dark1 !important;
  caret-color: $gray-dark1 !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-text-field-wrapper:hover {
  border-color: $gray-dark2 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
  border: 1px solid $gray-light1;
  border-radius: 12px;
}

.mdc-line-ripple {
  display: none;
}

.mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.pirexpo-textarea {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label,
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-floating-label--float-above {
    color: $gray-light4;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-floating-label,
  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-floating-label--float-above {
    color: $gray-dark1 !important;
  }
}

ngx-mat-input-tel .country-selector {
  opacity: 1 !important;
}
