$black: #000;
$black-dark-cyan: #1e2020;
$black-opacity4: rgb(0 0 0 / 0.04);
$black-opacity10: rgb(0 0 0 / 0.1);
$black-opacity20: rgb(0 0 0 / 0.2);
$black-opacity30: rgb(0 0 0 / 0.3);
$black-opacity40: rgb(0 0 0 / 0.4);
$black-opacity50: rgb(0 0 0 / 0.5);
$black-opacity60: rgb(0 0 0 / 0.6);
$black-opacity80: rgb(0 0 0 / 0.8);
$black-opacity85: rgb(0 0 0 / 0.85);
$white: #fff;
$white-opacity6: rgb(255 255 255 / 0.06);
$white-opacity10: rgb(255 255 255 / 0.1);
$white-opacity20: rgb(255 255 255 / 0.2);
$white-opacity30: rgb(255 255 255 / 0.3);
$white-opacity70: rgb(255 255 255 / 0.7);
$white-opacity80: rgb(255 255 255 / 0.8);
$gray: #6b6b6b;
$gray-light1: #c8c8c8;
$gray-light2: #e1e1e1;
$gray-light3: #fafafa;
$gray-light4: #b8b8b8;
$gray-light5: #efefef;
$gray-light6: #f4f4f4;
$gray-light7: #c5c4c4;
$gray-dark1: #2f2f2f;
$gray-dark2: rgb(119 119 119); // 777777
$gray-dark3: #969696;
$gray-dark4: #a9a9a9;
$gray-dark5: rgb(103 103 103); // 676767

$red: #da2b3e;
$red-pure: rgb(251 0 9); // FB0009
$red-pale: #ffebed;
$red-dark: rgb(89 42.4 47.5); // 592A2F
$red-soft: #e36c79;
$red-moderate1: #d85d6a;
$red-moderate2: #d35865;
$red-opacity20: rgb(218 43 62 / 0.12);
$blue: rgb(0 120 255); // 0078FF
$blue-dark-grayish: #888f9f;
$blue-grayish: #bdbebf;
$blue-light: #e7f1fc;
$blue-dark-moderate: #587fab;
$blue-slightly-desaturated: #6e9acb;
$blue-soft: #85adda;
$blue-light-grayish: #f0f3f8;
$orange: rgb(255 119 0); // FF7700
$orange-strong: #ce6910;
$orange-bright: #f69137;
$orange-light: #ffa353;
$orange-light-grayish: #fef2e7;
$violet-light-grayish: #eee6f2;
$violet-moderate: #915ab5;
$violet-soft1: #b672e1;
$violet-soft2: #bd85e0;
$button-hover: #e56a78;
$button-active: #e04b5b;
$button-disabled: #f2b5bc;
