.tickets-section {
  @include media(desktop) {
    &__column {
      margin-top: 48px;
    }

    &__main-column {
      width: 709px;
    }

    &__additional-column {
      width: 347px;
    }

    .text {
      font-size: 18px;
    }

    .currency-icon-small {
      height: 11px;
    }

    .restore-tickets {
      margin: 18px 0 28px;
    }

    .restore-icon {
      margin-right: 10px;

      @include box(19px);
    }

    .form-box {
      padding: 32px;
    }

    .secondary-text {
      font-size: 14px;
    }

    .ticket-price-number {
      font-size: 14px;
    }

    .ticket-description {
      &:not(:first-of-type) {
        padding-top: 14px;
      }

      &:not(:last-of-type) {
        padding-bottom: 14px;
      }
    }

    .total-price-container {
      padding-top: 16px;
    }

    .total-price-text {
      font-size: 18px;
    }

    .total-price-number {
      font-size: 24px;
    }

    .currency-icon-medium {
      @include box(16px);
    }

    .download-icon {
      @include box(16px);
    }

    .download-pdf-btn {
      padding: 29px;
    }

    .download-pdf-text {
      font-size: 16px;
    }
  }

  @include media(ipad-pro) {
    &__column {
      margin-top: 40px;
    }

    &__main-column {
      width: 100%;
      margin-right: 0;
    }

    &__additional-column {
      width: 100%;
    }

    .text {
      font-size: 16px;
    }

    .info-box__text {
      font-size: 16px !important;
    }

    .restore-tickets {
      margin: 16px 0 32px;
      font-size: 18px;
    }

    .ticket-description {
      &:not(:first-of-type) {
        padding-top: 18px;
      }

      &:not(:last-of-type) {
        padding-bottom: 18px;
      }
    }

    .ticket-price-number {
      font-size: 16px;
    }

    .total-price-text,
    .total-price-number {
      font-size: 18px;
    }

    .total-price-container {
      padding-top: 26px;
    }

    .currency-icon-medium {
      @include box(12px, 15px);
    }

    .download-pdf-btn {
      padding: 26px;
    }
  }

  @include media(ipad) {
    &__additional-column {
      margin-top: 48px;
    }

    .text {
      font-size: 14px;
    }

    .link {
      display: inline !important;
      color: $black-opacity80;
    }

    .link-text {
      &::after {
        width: 98% !important;
        background-color: $black-opacity80 !important;
      }
    }

    .info-box__text {
      font-size: 14px !important;
    }

    .restore-tickets {
      display: block !important;
      margin-top: 20px;
      font-size: 16px;
    }

    .form-box {
      padding: 24px 20px;
    }

    .secondary-text {
      font-size: 16px;
    }

    .ticket-description {
      &:not(:first-of-type) {
        padding-top: 16px;
      }

      &:not(:last-of-type) {
        padding-bottom: 16px;
      }
    }

    .total-price-container {
      padding-top: 18px;
    }

    .download-pdf-btn {
      padding: 18px 20px;
    }
  }

  &__column {
    margin-top: 56px;
  }

  &__form {
    width: 100%;
  }

  &__main-column {
    width: 848px;
    margin-right: 16px;
  }

  &__additional-column {
    width: 416px;
  }

  .restore-tickets {
    margin: 20px 0 32px;
  }

  .restore-icon {
    margin-right: 10px;

    @include box(20px);
  }

  .link {
    color: $red;
  }

  .text {
    font-size: 20px;
    line-height: 140%;
    color: $black-opacity80;
  }

  .info-box {
    margin-bottom: 16px;
  }

  .mat-spinner {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);

    width: 35px !important;
    height: 35px !important;

    svg {
      width: 35px !important;
      height: 35px !important;
    }
  }

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $white;
  }

  .form-box {
    padding: 40px;
    background-color: $white;
    border: 1px solid $black-opacity10;
    border-radius: 16px;
  }

  .secondary-text {
    font-size: 16px;
    line-height: 140%;
    color: $gray-dark1;
  }

  .ticket-description {
    position: relative;

    &:not(:first-of-type) {
      padding-top: 16px;
    }

    &:not(:last-of-type) {
      padding-bottom: 16px;
    }

    &:not(:last-of-type)::after {
      content: '';

      position: absolute;
      bottom: 0;

      opacity: 0.1;
      background-color: $gray-dark1;

      @include box(100%, 1px);
    }
  }

  .ticket-price-number {
    margin-right: 4px;

    font-size: 20px;
    font-weight: 600;
    line-height: 100%;
    color: $gray-dark1;
  }

  .currency-icon-small {
    @include box(13px);
  }

  .currency-icon-medium {
    @include box(20px);
  }

  .total-price-container {
    position: relative;
    padding-top: 18px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      background-color: $gray-dark1;

      @include box(100%, 1px);
    }
  }

  .discount-price-container {
    margin-bottom: 10px;
  }

  .total-price-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 120%;
    color: $gray-dark1;
  }

  .total-price-number {
    margin-right: 4px;

    font-size: 26px;
    font-weight: 600;
    line-height: 100%;
    color: $gray-dark1;
  }

  .asterisk {
    top: -0.2em;
    font-size: 85%;
    line-height: 100%;
  }

  .download-icon {
    @include box(18px);
  }

  .bin-icon {
    @include box(20px);
  }

  .arrow-chevron-icon {
    @include box(27px);
  }

  .download-pdf-btn {
    cursor: pointer;
    padding: 32px;
    background: $gray-light5;
    border: none;
  }

  .download-pdf-text {
    font-size: 18px;
    line-height: 120%;
    color: $gray;
  }
}

.tickets-title {
  width: 100%;
  margin-bottom: 26px;

  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
  color: $gray-dark4;

  @include media(desktop) {
    margin-bottom: 22px;
    font-size: 13px;
    font-weight: 600;
  }

  @include media(ipad-pro) {
    margin-bottom: 26px;
    font-size: 14px;
  }

  @include media(ipad) {
    margin-bottom: 22px;
  }
}

.ticket-name {
  width: 70%;
  font-size: 16px;
  line-height: 140%;
  color: $gray-dark1;

  @include media(desktop) {
    font-size: 14px;
  }

  @include media(ipad-pro) {
    font-size: 16px;
  }
}

.ticket-type,
.ticket-error {
  margin: 6px 10px 0 0;
  font-size: 12px;
  line-height: 140%;
  color: $gray-dark3;

  &_lg {
    font-size: 14px;
  }
}

.common-ticket-error {
  margin-top: 10px;
  font-size: 14px;
  line-height: 140%;
  color: $gray-dark3;
}

.tickets-counter {
  width: 100%;
  margin-top: 18px;
  padding-bottom: 12px;
}
