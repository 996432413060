.pirexpo-formly {
  .formly-grid formly-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @include media(ipad) {
      grid-template-columns: repeat(1, 1fr);
      gap: 18px;
    }
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  formly-field.formly-grid:first-of-type > pirexpo-formly-survey-panel {
    margin-top: 0;
  }

  .pirexpo-checkbox + .pirexpo-checkbox {
    @include media(ipad) {
      display: block;
      margin-top: 8px;
    }
  }
}
