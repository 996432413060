.header-mat-menu-backdrop {
  // & + .cdk-overlay-connected-position-bounding-box {
  //   top: 85px !important;
  // }

  & + * .mat-mdc-menu-panel {
    min-width: 170px;
    margin-right: -30px;
    border-radius: 7px;

    // background: none;
    box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 0.1);

    .mat-mdc-menu-content {
      padding: 0;
    }
  }
}

.header-mat-menu-item {
  padding: 0 0 0 35px !important;

  font-family: $golos;
  font-size: 16px !important;
  font-weight: 500;
  color: $gray-dark1 !important;

  transition: background-color 0.3s ease;

  @include hover {
    &:hover {
      background-color: $gray-light3 !important;
    }
  }

  @include media(desktop) {
    font-size: 14px !important;
  }
}
