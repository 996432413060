.info-map {
  &__text-group {
    @include media(desktop) {
      padding-right: 20px;
    }

    @include media(ipad-pro) {
      order: 1;
      width: 100%;
      margin-top: 40px;
      padding-right: 0;
    }
  }

  &__inner-text-group {
    @include media(ipad-pro) {
      display: flex;
      justify-content: space-between;
    }

    @include media(ipad) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__text-block {
    @include media(ipad-pro) {
      width: 47%;
    }

    @include media(ipad) {
      width: 100%;
    }
  }

  &__place {
    margin-bottom: 14px;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;

    @include media(desktop) {
      margin-bottom: 8px;
      font-size: 32px;
      line-height: 42px;
    }

    @include media(ipad-pro) {
      margin-bottom: 10px;
      font-size: 30px;
      line-height: 39px;
    }

    @include media(ipad) {
      margin-bottom: 8px;
      font-size: 24px;
      line-height: 31px;
    }
  }

  &__text {
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 24px;
    color: $gray-dark1;

    @include media(desktop) {
      margin-bottom: 38px;
      font-size: 18px;
      line-height: 22px;
    }

    @include media(ipad-pro) {
      margin-bottom: 30px;
      font-size: 20px;
      line-height: 26px;
    }

    @include media(ipad) {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__text_mt {
    margin-top: 40px;

    @include media(desktop) {
      margin-top: 38px;
    }

    @include media(ipad-pro) {
      margin-top: 30px;
    }

    @include media(ipad) {
      margin-top: 20px;
    }
  }

  &__sub-title {
    margin-bottom: 10px;

    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: $gray-dark3;

    @include media(desktop) {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 20px;
    }

    @include media(ipad-pro) {
      margin-bottom: 6px;
      font-size: 16px;
      line-height: 22px;
    }

    @include media(ipad) {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__link {
    transition:
      color 0.3s ease-in,
      background-color 0.3s ease-in;

    @include hover {
      &:hover {
        color: $gray-dark2;

        &::after {
          background-color: $gray-dark2;
        }
      }
    }
  }
}
