.hotels {
  &__contacts {
    margin-top: 48px;

    @include media(desktop) {
      margin-top: 44px;
    }

    @include media(ipad) {
      margin-top: 48px;
    }

    &-text {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;

      @include media(desktop) {
        font-size: 18px;
        line-height: 25px;
      }
    }

    &-title {
      margin-bottom: 20px;

      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      color: $gray-dark4;

      @include media(desktop) {
        margin-bottom: 18px;
        font-size: 14px;
        line-height: 17px;
      }

      @include media(ipad-pro) {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 19px;
      }

      @include media(ipad) {
        margin-bottom: 18px;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}
