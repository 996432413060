.mdc-checkbox {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.mat-mdc-checkbox label {
  padding-left: 8px !important;

  @include media(ipad) {
    padding-left: 10px !important;
  }
}

.mdc-checkbox:hover .mdc-checkbox__ripple,
.mdc-checkbox .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple,
.mdc-checkbox
  .mdc-checkbox__native-control:focus:checked
  ~ .mdc-checkbox__ripple {
  display: none;
}

.mdc-checkbox__background {
  left: 0 !important;
  border-radius: 4px !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  background-color: $red !important;
  border-color: $red !important;
}

.mat-mdc-checkbox .mdc-form-field {
  color: $gray-dark1;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(
    :checked,
    :indeterminate,
    [data-indeterminate='true']
  )
  ~ .mdc-checkbox__background {
  border-color: $gray-dark1 !important;
}

.mat-mdc-checkbox.ng-invalid.ng-touched
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(
    :checked,
    :indeterminate,
    [data-indeterminate='true']
  )
  ~ .mdc-checkbox__background {
  border-color: $red !important;
}
