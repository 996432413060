$font-path: '/assets/fonts';

/* ----- GOLOS ----- */

@font-face {
  font-family: 'Golos Text';
  font-weight: 400;
  font-style: normal;
  src: url('#{$font-path}/Golos/GolosText-Regular.eot');
  src:
    local('Golos Text Regular'),
    local('GolosText-Regular'),
    url('#{$font-path}/Golos/GolosText-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Golos/GolosText-Regular.woff2') format('woff2'),
    url('#{$font-path}/Golos/GolosText-Regular.woff') format('woff'),
    url('#{$font-path}/Golos/GolosText-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Golos Text';
  font-weight: 500;
  font-style: normal;
  src: url('#{$font-path}/Golos/GolosText-Medium.eot');
  src:
    local('Golos Text Medium'),
    local('GolosText-Medium'),
    url('#{$font-path}/Golos/GolosText-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Golos/GolosText-Medium.woff2') format('woff2'),
    url('#{$font-path}/Golos/GolosText-Medium.woff') format('woff'),
    url('#{$font-path}/Golos/GolosText-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Golos Text';
  font-weight: 600;
  font-style: normal;
  src: url('#{$font-path}/Golos/GolosText-DemiBold.eot');
  src:
    local('Golos Text DemiBold'),
    local('GolosText-DemiBold'),
    url('#{$font-path}/Golos/GolosText-DemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Golos/GolosText-DemiBold.woff2') format('woff2'),
    url('#{$font-path}/Golos/GolosText-DemiBold.woff') format('woff'),
    url('#{$font-path}/Golos/GolosText-DemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Golos Text';
  font-weight: 700;
  font-style: normal;
  src: url('#{$font-path}/Golos/GolosText-Bold.eot');
  src:
    local('Golos Text Bold'),
    local('GolosText-Bold'),
    url('#{$font-path}/Golos/GolosText-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Golos/GolosText-Bold.woff2') format('woff2'),
    url('#{$font-path}/Golos/GolosText-Bold.woff') format('woff'),
    url('#{$font-path}/Golos/GolosText-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Golos Text';
  font-weight: 900;
  font-style: normal;
  src: url('#{$font-path}/Golos/GolosText-Black.eot');
  src:
    local('Golos Text Black'),
    local('GolosText-Black'),
    url('#{$font-path}/Golos/GolosText-Black.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Golos/GolosText-Black.woff2') format('woff2'),
    url('#{$font-path}/Golos/GolosText-Black.woff') format('woff'),
    url('#{$font-path}/Golos/GolosText-Black.ttf') format('truetype');
}

/* ----- Montserrat ----- */

@font-face {
  font-family: Montserrat;
  font-weight: 900;
  font-style: normal;
  src: url('#{$font-path}/Montserrat/Montserrat-Black.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-Black.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-Black.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-Black.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-Black.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: bold;
  font-style: normal;
  src: url('#{$font-path}/Montserrat/Montserrat-Bold.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-Bold.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-Bold.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 900;
  font-style: italic;
  src: url('#{$font-path}/Montserrat/Montserrat-BlackItalic.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-BlackItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-BlackItalic.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-BlackItalic.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-BlackItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
  src: url('#{$font-path}/Montserrat/Montserrat-Regular.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-Regular.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  font-style: normal;
  src: url('#{$font-path}/Montserrat/Montserrat-Medium.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-Medium.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-Medium.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 300;
  font-style: italic;
  src: url('#{$font-path}/Montserrat/Montserrat-LightItalic.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-LightItalic.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-LightItalic.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-LightItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: bold;
  font-style: normal;
  src: url('#{$font-path}/Montserrat/Montserrat-ExtraBold.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-ExtraBold.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 300;
  font-style: normal;
  src: url('#{$font-path}/Montserrat/Montserrat-Light.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-Light.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-Light.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-Light.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 600;
  font-style: normal;
  src: url('#{$font-path}/Montserrat/Montserrat-SemiBold.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-SemiBold.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  font-style: italic;
  src: url('#{$font-path}/Montserrat/Montserrat-MediumItalic.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-MediumItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-MediumItalic.woff2')
      format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-MediumItalic.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-MediumItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 200;
  font-style: italic;
  src: url('#{$font-path}/Montserrat/Montserrat-ExtraLightItalic.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-ExtraLightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-ExtraLightItalic.woff2')
      format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-ExtraLightItalic.woff')
      format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-ExtraLightItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: normal;
  font-style: italic;
  src: url('#{$font-path}/Montserrat/Montserrat-Italic.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-Italic.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-Italic.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-Italic.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: bold;
  font-style: italic;
  src: url('#{$font-path}/Montserrat/Montserrat-BoldItalic.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-BoldItalic.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-BoldItalic.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: bold;
  font-style: italic;
  src: url('#{$font-path}/Montserrat/Montserrat-ExtraBoldItalic.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-ExtraBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-ExtraBoldItalic.woff2')
      format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-ExtraBoldItalic.woff')
      format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-ExtraBoldItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 200;
  font-style: normal;
  src: url('#{$font-path}/Montserrat/Montserrat-ExtraLight.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-ExtraLight.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-ExtraLight.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-ExtraLight.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 600;
  font-style: italic;
  src: url('#{$font-path}/Montserrat/Montserrat-SemiBoldItalic.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-SemiBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-SemiBoldItalic.woff2')
      format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-SemiBoldItalic.woff')
      format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-SemiBoldItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 100;
  font-style: normal;
  src: url('#{$font-path}/Montserrat/Montserrat-Thin.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-Thin.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-Thin.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-Thin.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 100;
  font-style: italic;
  src: url('#{$font-path}/Montserrat/Montserrat-ThinItalic.eot');
  src:
    url('#{$font-path}/Montserrat/Montserrat-ThinItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Montserrat/Montserrat-ThinItalic.woff2') format('woff2'),
    url('#{$font-path}/Montserrat/Montserrat-ThinItalic.woff') format('woff'),
    url('#{$font-path}/Montserrat/Montserrat-ThinItalic.ttf') format('truetype');
}

/* ----- GEOMETRIA ----- */

@font-face {
  font-family: Geometria;
  font-weight: 100;
  font-style: normal;
  src: url('#{$font-path}/Geometria/Geometria-Thin.eot');
  src:
    url('#{$font-path}/Geometria/Geometria-Thin.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria-Thin.woff') format('woff'),
    url('#{$font-path}/Geometria/Geometria-Thin.ttf') format('truetype');
}

@font-face {
  font-family: Geometria;
  font-weight: 500;
  font-style: italic;
  src: url('#{$font-path}/Geometria/Geometria-MediumItalic.eot');
  src:
    url('#{$font-path}/Geometria/Geometria-MediumItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria-MediumItalic.woff') format('woff'),
    url('#{$font-path}/Geometria/Geometria-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: Geometria;
  font-weight: normal;
  font-style: italic;
  src: url('#{$font-path}/Geometria/Geometria-Italic.eot');
  src:
    url('#{$font-path}/Geometria/Geometria-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria-Italic.woff') format('woff'),
    url('#{$font-path}/Geometria/Geometria-Italic.ttf') format('truetype');
}

@font-face {
  font-family: Geometria;
  font-weight: 900;
  font-style: normal;
  src: url('#{$font-path}/Geometria/Geometria-Heavy.eot');
  src:
    url('#{$font-path}/Geometria/Geometria-Heavy.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria-Heavy.woff') format('woff'),
    url('#{$font-path}/Geometria/Geometria-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: Geometria;
  font-weight: 800;
  font-style: italic;
  src: url('#{$font-path}/Geometria/Geometria-ExtraBoldItalic.eot');
  src:
    url('#{$font-path}/Geometria/Geometria-ExtraBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria-ExtraBoldItalic.woff') format('woff'),
    url('#{$font-path}/Geometria/Geometria-ExtraBoldItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: Geometria;
  font-weight: 500;
  font-style: normal;
  src: url('#{$font-path}/Geometria/Geometria-Medium.eot');
  src:
    url('#{$font-path}/Geometria/Geometria-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria-Medium.woff') format('woff'),
    url('#{$font-path}/Geometria/Geometria-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Geometria;
  font-weight: bold;
  font-style: italic;
  src: url('#{$font-path}/Geometria/Geometria-BoldItalic.eot');
  src:
    url('#{$font-path}/Geometria/Geometria-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria-BoldItalic.woff') format('woff'),
    url('#{$font-path}/Geometria/Geometria-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: Geometria;
  font-weight: 300;
  font-style: italic;
  src: url('#{$font-path}/Geometria/Geometria-LightItalic.eot');
  src:
    url('#{$font-path}/Geometria/Geometria-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria-LightItalic.woff') format('woff'),
    url('#{$font-path}/Geometria/Geometria-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: Geometria;
  font-weight: 900;
  font-style: italic;
  src: url('#{$font-path}/Geometria/Geometria-HeavyItalic.eot');
  src:
    url('#{$font-path}/Geometria/Geometria-HeavyItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria-HeavyItalic.woff') format('woff'),
    url('#{$font-path}/Geometria/Geometria-HeavyItalic.ttf') format('truetype');
}

@font-face {
  font-family: Geometria;
  font-weight: normal;
  font-style: normal;
  src: url('#{$font-path}/Geometria/Geometria.eot');
  src:
    url('#{$font-path}/Geometria/Geometria.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria.woff') format('woff'),
    url('#{$font-path}/Geometria/Geometria.ttf') format('truetype');
}

@font-face {
  font-family: Geometria;
  font-weight: bold;
  font-style: normal;
  src: url('#{$font-path}/Geometria/Geometria-Bold.eot');
  src:
    url('#{$font-path}/Geometria/Geometria-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria-Bold.woff') format('woff'),
    url('#{$font-path}/Geometria/Geometria-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Geometria;
  font-weight: 800;
  font-style: normal;
  src: url('#{$font-path}/Geometria/Geometria-ExtraBold.eot');
  src:
    url('#{$font-path}/Geometria/Geometria-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria-ExtraBold.woff') format('woff'),
    url('#{$font-path}/Geometria/Geometria-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: Geometria;
  font-weight: 100;
  font-style: italic;
  src: url('#{$font-path}/Geometria/Geometria-ThinItalic.eot');
  src:
    url('#{$font-path}/Geometria/Geometria-ThinItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria-ThinItalic.woff') format('woff'),
    url('#{$font-path}/Geometria/Geometria-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: Geometria;
  font-weight: 200;
  font-style: italic;
  src: url('#{$font-path}/Geometria/Geometria-ExtraLightItalic.eot');
  src:
    url('#{$font-path}/Geometria/Geometria-ExtraLightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria-ExtraLightItalic.woff')
      format('woff'),
    url('#{$font-path}/Geometria/Geometria-ExtraLightItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: Geometria;
  font-weight: 300;
  font-style: normal;
  src: url('#{$font-path}/Geometria/Geometria-Light.eot');
  src:
    url('#{$font-path}/Geometria/Geometria-Light.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria-Light.woff') format('woff'),
    url('#{$font-path}/Geometria/Geometria-Light.ttf') format('truetype');
}

@font-face {
  font-family: Geometria;
  font-weight: 200;
  font-style: normal;
  src: url('#{$font-path}/Geometria/Geometria-ExtraLight.eot');
  src:
    url('#{$font-path}/Geometria/Geometria-ExtraLight.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/Geometria/Geometria-ExtraLight.woff') format('woff'),
    url('#{$font-path}/Geometria/Geometria-ExtraLight.ttf') format('truetype');
}
