// stylelint-disable-next-line selector-no-qualifying-type
div.mat-mdc-autocomplete-panel {
  margin-top: 10px;
  border-radius: 6px !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  font-weight: 600 !important;
}
