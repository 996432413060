.themes-header {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  min-height: 547px;
  padding-bottom: 48px;

  @include media(desktop) {
    min-height: 36vw;
    padding-bottom: 40px;
  }

  @include media(ipad-pro) {
    min-height: 36vw;
    padding-bottom: 20px;
  }

  &__title {
    margin: 0 0 32px;

    font-size: 70px;
    font-weight: 700;
    line-height: 100%;
    color: $black;

    @include media(desktop) {
      margin-bottom: 32px;
      font-size: 52px;
      line-height: 110%;
    }

    @include media(ipad-pro) {
      margin-bottom: 32px;
      font-size: 44px;
    }

    @include media(ipad) {
      margin-bottom: 24px;
      font-size: 36px;
      line-height: 100%;
    }
  }

  &__sub-title {
    font-size: 24px;
    line-height: 130%;
    color: $white;

    @include media(desktop) {
      font-size: 18px;
    }

    @include media(ipad) {
      font-size: 16px;
    }
  }
}
