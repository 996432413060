$app-padding-desktop: 80px;
$app-padding-laptop: 72px;
$app-padding-tablet: 32px;
$app-padding-mobile: 20px;
$app-max-width: 1520px;

.container {
  margin: 0 auto;
  padding: 0 calc((100vw - $app-max-width) / 2);

  @include more-than($original + 1) {
    max-width: 1920px;
    padding: 0 $app-padding-desktop;
  }

  @include media(desktop) {
    padding: 0 $app-padding-laptop;
  }

  @include media(ipad-pro) {
    padding: 0 $app-padding-tablet;
  }

  @include media(ipad) {
    padding: 0 $app-padding-mobile;
  }
}

.page-block {
  & + & {
    margin-top: 112px;

    @include media(desktop) {
      margin-top: 98px;
    }

    @include media(ipad-pro) {
      margin-top: 80px;
    }

    @include media(ipad) {
      margin-top: 64px;
    }
  }
}

.page-section {
  display: grid;
  grid-template-columns: 25% 75%;
  height: 100%;

  @include more-than($original + 1) {
    max-width: 1920px;
    margin: 0 auto;
  }

  @include media(ipad-pro) {
    grid-template-columns: 100%;
  }

  &_full-width {
    display: block;
    grid-template-columns: none;
  }

  &_base {
    padding-top: 112px;

    @include media(desktop) {
      padding-top: 80px;
    }

    @include media(ipad-pro) {
      padding-top: 72px;
    }

    @include media(ipad) {
      padding-top: 48px;
    }
  }

  &_secondary {
    padding-top: 75px;

    @include media(desktop) {
      padding-top: 64px;
    }

    @include media(ipad-pro) {
      padding-top: 40px;
    }

    @include media(ipad) {
      padding-top: 32px;
    }

    .page-block:nth-child(2) {
      margin-top: 82px;

      @include media(desktop) {
        margin-top: 72px;
      }

      @include media(ipad-pro) {
        margin-top: 80px;
      }

      @include media(ipad) {
        margin-top: 64px;
      }
    }
  }

  &__aside {
    height: 100%;
    padding-left: calc((100vw - $app-max-width) / 2);

    @include more-than($original + 1) {
      padding-left: $app-padding-desktop;
    }

    @include media(desktop) {
      padding-left: $app-padding-laptop;
    }

    @include media(ipad-pro) {
      display: none;
    }

    &-list {
      position: sticky;
      top: 112px;
      display: inline-block;
    }

    &-item {
      cursor: pointer;

      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      color: $black-opacity40;

      transition: color 0.3s ease;

      @include hover {
        &:hover {
          color: $red;
        }
      }

      @include media(desktop) {
        font-size: 16px;
        line-height: 19px;
      }

      &_active {
        color: $red;
      }

      & + & {
        margin-top: 16px;

        @include media(desktop) {
          margin-top: 14px;
        }
      }
    }
  }

  &__text {
    margin-top: 24px;
    font-size: 28px;
    line-height: 36px;

    @include media(desktop) {
      margin-top: 22px;
      font-size: 22px;
      line-height: 28px;
    }

    @include media(ipad-pro) {
      font-size: 20px;
      line-height: 26px;
    }

    @include media(ipad) {
      margin-top: 28px;
      font-size: 18px;
      line-height: 21px;
    }

    &_no-margin {
      margin-top: 0 !important;
    }
  }

  &__title {
    margin-bottom: 32px;

    @include media(ipad-pro) {
      display: none;
    }
  }
}

.block-container {
  padding-right: calc((100vw - $app-max-width) / 2);

  @include more-than($original + 1) {
    padding-right: $app-padding-desktop;
  }

  @include media(desktop) {
    padding-right: $app-padding-laptop;
  }

  @include media(ipad-pro) {
    padding: 0 $app-padding-tablet;
  }

  @include media(ipad) {
    padding: 0 $app-padding-mobile;
  }

  &_pl {
    padding-left: 80px;

    @include media(desktop) {
      padding-left: 72px;
    }

    @include media(ipad-pro) {
      padding: 0 $app-padding-tablet;
    }

    @include media(ipad) {
      padding: 0 $app-padding-mobile;
    }
  }
}

.section-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;

  @include media(desktop) {
    font-size: 32px;
    line-height: 38px;
  }

  @include media(ipad-pro) {
    font-size: 30px;
    line-height: 36px;
  }

  @include media(ipad) {
    font-size: 24px;
    line-height: 28px;
  }
}

.section-title-margin {
  margin-bottom: 40px;

  @include media(desktop) {
    margin-bottom: 30px;
  }

  @include media(ipad-pro) {
    margin-bottom: 26px;
  }

  @include media(ipad) {
    margin-bottom: 24px;
  }
}

.section-alt-title-margin {
  margin-bottom: 24px;

  @include media(desktop) {
    margin-bottom: 22px;
  }

  @include media(ipad-pro) {
    margin-bottom: 20px;
  }

  @include media(ipad) {
    margin-bottom: 24px;
  }
}

.secondary-section-text {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 140%;

  @include media(desktop) {
    font-size: 18px;
  }

  @include media(ipad-pro) {
    font-size: 20px;
  }

  @include media(ipad) {
    font-size: 18px;
    line-height: 22px;
  }
}

// TODO: поменять нейминг класса (исп не только как лоайдинг текст)
.section-loading-text {
  width: 100%;
  margin: 50px 0;

  font-size: 28px;
  line-height: 130%;
  text-align: center;

  @include media(ipad-pro) {
    font-size: 20px;
  }
}
