.participation-popup {
  background-color: $gray-light3;

  &__back-button {
    display: none !important;

    @include media(ipad) {
      position: relative;
      display: inline-block !important;
      margin: 28px 0 20px;
    }
  }

  .back-icon {
    @include box(20px);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    @include media(desktop) {
      margin-bottom: 16px;
    }

    @include media(ipad-pro) {
      margin-bottom: 28px;
    }
  }

  &__bottom-container {
    &-group {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include media(ipad) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }

  &__info-group {
    @include media(ipad) {
      display: none;
    }
  }

  &__img-container {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 224px;

    background: $white;
    border: 1px solid $gray-light2;
    border-radius: 20px;

    @include box(224px);

    @include media(desktop) {
      min-width: 192px;

      @include box(192px);
    }

    @include media(ipad-pro) {
      min-width: 284px;
      border-radius: 24px;

      @include box(284px);
    }

    @include media(ipad) {
      min-width: 190px;
      margin: 32px auto;
      border-radius: 20px;

      @include box(190px);
    }
  }

  &__img {
    object-fit: contain;

    @include box(167px);

    @include media(desktop) {
      @include box(143px);
    }

    @include media(ipad-pro) {
      @include box(212px);
    }

    @include media(ipad) {
      @include box(141px);
    }
  }

  &__info {
    margin-left: 48px;

    @include media(desktop) {
      margin-left: 34px;
    }

    @include less-than(501px) {
      margin-left: 0;
    }
  }

  &__name {
    margin-bottom: 24px;

    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    color: $gray-dark1;

    @include media(desktop) {
      margin-bottom: 22px;
      font-size: 26px;
      line-height: 31px;
    }

    @include media(ipad) {
      margin: 12px 0;
      font-size: 34px;
      line-height: 110%;
    }
  }

  &__info-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-size: 18px;
    line-height: 120%;
    color: $blue-dark-grayish !important;

    @include media(desktop) {
      font-size: 16px;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    @include media(ipad-pro) {
      font-size: 18px;
    }

    @include media(ipad) {
      font-size: 16px;

      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }

    a {
      color: $blue-dark-grayish !important;
      transition: color 0.3s ease-in;

      @include hover {
        &:hover {
          color: darken($color: $blue-dark-grayish, $amount: 20%) !important;
        }
      }

      // stylelint-disable-next-line selector-no-qualifying-type
      &.classificator-link {
        @include hover {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__icon {
    min-width: 24px;
    margin-right: 15px;

    @include box(24px);

    @include media(desktop) {
      margin-right: 12px;
    }

    @include media(ipad) {
      min-width: 20px;

      @include box(20px);
    }

    svg {
      min-width: 24px;

      @include box(24px);

      @include media(ipad) {
        min-width: 20px;

        @include box(20px);
      }
    }
  }

  &__location-block {
    position: relative;
    margin-top: 64px;
    padding: 40px 0;

    @include media(desktop) {
      margin-top: 58px;
    }

    @include media(ipad) {
      margin: 33px 0 26px;
      padding: 36px 0;
    }

    &::before,
    &::after {
      content: '';

      position: absolute;
      left: 0;

      opacity: 0.1;
      background-color: $gray-dark1;

      @include box(100%, 1px);
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &__location-title {
    position: absolute;
    top: -13px;
    left: 0;

    padding-right: 16px;

    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: $gray-dark1;

    background-color: $gray-light3;

    @include media(desktop) {
      top: -11px;
      font-size: 16px;
      line-height: 19px;
    }

    @include media(ipad) {
      font-size: 14px;
      line-height: 120%;
    }
  }

  &__location {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-size: 22px;
    line-height: 120%;
    color: $gray-dark1;

    @include media(desktop) {
      font-size: 20px;
    }

    @include media(ipad) {
      font-size: 16px;
    }
  }

  &__location-icon {
    margin-right: 14px;

    @include box(24px);

    @include media(desktop) {
      margin-right: 12px;
    }

    svg {
      @include box(24px);
    }
  }

  &__hall {
    position: relative;
    margin-right: 20px;

    @include media(ipad) {
      margin-right: 16px;
    }

    &::after {
      content: '•';
      position: absolute;
      right: -12px;
      font-size: 15px;
    }
  }

  &__show-map {
    position: relative;
    font-size: 16px;
    line-height: 16px;
    color: $red;

    @include media(desktop) {
      font-size: 14px;
      line-height: 14px;
    }

    &::after {
      content: '';

      position: absolute;
      bottom: -3px;
      left: 0;

      background-color: $red;

      @include box(100%, 1px);
    }
  }

  &__description {
    margin-top: 40px;

    font-size: 18px;
    line-height: 140%;
    color: $gray-dark1;
    white-space: pre-line;

    @include media(desktop) {
      font-size: 16px;
      line-height: 150%;
    }

    @include media(ipad-pro) {
      margin-top: 48px;
      font-size: 18px;
      line-height: 140%;
    }

    @include media(ipad) {
      position: relative;
      margin-top: 26px;
      padding-top: 25px;
      font-size: 16px;

      &::before {
        content: '';

        position: absolute;
        top: 0;
        left: 0;

        opacity: 0.1;
        background-color: $gray-dark1;

        @include box(100%, 1px);
      }
    }
  }

  &__slider {
    padding-left: 72px;

    @include media(desktop) {
      padding-left: 48px;
    }

    @include media(ipad-pro) {
      padding-left: 32px;
    }

    @include media(ipad) {
      padding-left: 20px;
    }
  }

  &__events-slider {
    margin-top: 95px;

    @include media(desktop) {
      margin-top: 61px;
    }

    @include media(ipad-pro) {
      margin-top: 72px;
    }

    @include media(ipad) {
      margin-top: 64px;
    }
  }

  &__best-price-slider,
  &__award-slider {
    margin-top: 72px;

    @include media(desktop) {
      margin-top: 64px;
    }

    @include media(ipad-pro) {
      margin-top: 72px;
    }

    @include media(ipad) {
      margin-top: 64px;
    }
  }

  &__award-slider {
    margin-bottom: 79px;

    @include media(desktop) {
      margin-bottom: 64px;
    }

    @include media(ipad-pro) {
      margin-bottom: 90px;
    }

    @include media(ipad) {
      margin-bottom: 64px;
    }
  }

  &__slider-title {
    margin-bottom: 24px;

    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    color: $gray-dark1;

    @include media(desktop) {
      margin-bottom: 22px;
      font-size: 24px;
      line-height: 29px;
    }

    @include media(ipad-pro) {
      margin-bottom: 26px;
      font-size: 28px;
      line-height: 34px;
    }

    @include media(ipad) {
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 29px;
    }
  }
}
