.mat-mdc-progress-spinner {
  svg {
    max-width: none;
  }

  circle {
    stroke: $white;
  }
}

.loading-spinner.mat-mdc-progress-spinner circle {
  stroke: $red;
}
