.pirexpo-form {
  &__block {
    & + & {
      margin-top: 56px;
    }

    &_columns {
      display: flex;

      @include media(ipad) {
        flex-direction: column;
      }
    }

    &-col {
      width: 50%;

      @include media(ipad) {
        & + & {
          margin-top: 56px;
        }
      }
    }
  }

  &__content {
    margin-bottom: 40px;
    padding: 40px;

    background-color: $white;
    border: 1px solid $black-opacity10;
    border-radius: 16px;

    @include media(desktop) {
      padding: 32px;
    }

    @include media(ipad) {
      margin-bottom: 44px;
      padding: 24px 20px;
    }
  }

  &__title {
    margin-bottom: 26px;

    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: $gray-dark4;

    @include media(desktop) {
      margin-bottom: 22px;
      font-size: 13px;
      line-height: 16px;
    }

    @include media(ipad-pro) {
      margin-bottom: 26px;
      font-size: 14px;
      line-height: 17px;
    }

    @include media(ipad) {
      margin-bottom: 22px;
    }
  }

  &__group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @include media(ipad) {
      grid-template-columns: repeat(1, 1fr);
      gap: 18px;
    }
  }

  &__textarea {
    resize: none !important;
    height: 150px !important;

    @include media(desktop) {
      height: 120px !important;
    }

    @include media(ipad-pro) {
      height: 180px !important;
    }

    @include media(ipad-pro) {
      height: 200px !important;
    }
  }

  &__secondary-text {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    @include media(desktop) {
      margin-top: 32px;
      font-size: 13px;
      line-height: 18px;
    }

    @include media(ipad-pro) {
      margin-top: 20px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
