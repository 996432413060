.header-nav-slider {
  margin-top: 15px;
}

.header-download-button {
  @include hover {
    &:hover span {
      opacity: 1;
    }
  }

  span {
    margin-left: 16px;

    font-size: 16px;
    line-height: 19px;
    color: $white;

    opacity: 0.8;

    transition: opacity 0.3s ease-in;

    @include media(desktop) {
      margin-left: 14px;
      font-size: 14px;
      line-height: 17px;
    }

    @include media(ipad) {
      margin-left: 12px;
    }
  }
}

.header-download-icon {
  @include box(18px);

  @include media(desktop) {
    @include box(16px);
  }

  @include media(ipad-pro) {
    @include box(12px);
  }
}
