.mat-mdc-select-panel {
  padding: 0 !important;
  border-radius: 8px !important;
  box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 0.1) !important;
}

.mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background-color: $gray-light3 !important;
}

.mat-mdc-option {
  color: $gray-dark1 !important;

  .mat-pseudo-checkbox {
    display: none;
  }
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background-color: $gray-light5 !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  font-weight: 700;
  color: $gray-dark1 !important;
}

.mat-mdc-select-arrow-wrapper {
  width: 24px !important;
  height: 24px !important;
}

.mat-mdc-select-arrow {
  width: 20px !important;
  height: 20px !important;
  color: $gray-dark1 !important;
}

.mat-mdc-select-value-text {
  font-weight: 600;
  color: $gray-dark1;
}
