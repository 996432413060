.order__timer-text-break {
  @include media(laptop) {
    display: none;
  }

  @include media(ipad-pro) {
    display: inline-block;
  }

  @include less-than(500px) {
    display: none;
  }
}
