/* stylelint-disable at-rule-empty-line-before */
@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin hide-scrollbar() {
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

@mixin hover() {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin less-than($width) {
  @media only screen and (max-width: ($width - 1px)) {
    @content;
  }
}

@mixin more-than($width) {
  @media only screen and (min-width: $width) {
    @content;
  }
}

@mixin width-between($min, $max) {
  @media only screen and (min-width: $min) and (max-width: ($max - 1px)) {
    @content;
  }
}

@mixin media($breakpoint) {
  @if $breakpoint == original {
    @media screen and (max-width: ($original - 1px)) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media screen and (max-width: ($desktop - 1px)) {
      @content;
    }
  } @else if $breakpoint == laptop {
    @media screen and (max-width: ($laptop - 1px)) {
      @content;
    }
  } @else if $breakpoint == ipad-pro {
    @media screen and (max-width: ($ipad-pro - 1px)) {
      @content;
    }
  } @else if $breakpoint == ipad {
    @media screen and (max-width: ($ipad - 1px)) {
      @content;
    }
  } @else if $breakpoint == mobile {
    @media screen and (max-width: ($mobile - 1px)) {
      @content;
    }
  } @else {
    @error "Breakpoint: #{$breakpoint} doesn't exists.";
  }
}

@mixin ng-deep {
  :host ::ng-deep {
    @content;
  }
}
