@import 'style-utils';

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.direction-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.color-red {
  color: $red !important;
}

.color-white {
  color: $white;
}

.color-gray {
  color: $gray-dark1;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.only-mobile-tablet {
  display: none !important;

  @include media(ipad-pro) {
    display: block !important;
  }
}

.only-mobile {
  display: none !important;

  @include media(ipad) {
    display: block !important;
  }
}

.only-tablet {
  display: none !important;

  @include media(ipad-pro) {
    display: block !important;
  }

  @include media(ipad) {
    display: none !important;
  }
}

.only-desktop {
  @include media(ipad-pro) {
    display: none !important;
  }
}

.hide-mobile {
  @include media(ipad) {
    display: none !important;
  }
}

.no-margin {
  margin: 0 !important;
}

.underline {
  position: relative;

  &::after {
    content: '';

    position: absolute;
    bottom: 1px;
    left: 0;

    background-color: $black-opacity80;

    @include box(100%, 1px);
  }
}

.margin-inline-auto {
  margin-inline: auto;
}

.mt-2 {
  margin-top: 2rem;
}

.opacity-3 {
  opacity: 0.3;
}

.no-max-width {
  max-width: none !important;
}
