.detail-page-link {
  display: inline-block;
  margin-top: 30px;
  color: $gray-dark1;

  @include media(ipad-pro) {
    padding-left: 32px;
  }

  @include media(ipad) {
    padding-left: 20px;
  }

  &-arrow {
    transform: rotateY(180deg);
    color: $gray-dark1;

    @include box(20px);
  }

  a {
    display: flex;
    align-items: center;
  }

  &-text {
    margin-left: 3px;
    font-size: 16px;
    font-weight: 400;
    color: $gray-dark1;

    @include media(desktop) {
      font-size: 14px;
    }
  }
}

.dates-grid {
  display: grid;
  grid-template-columns: repeat(4, 54px);
  gap: 10px;

  @include media(desktop) {
    grid-template-columns: repeat(4, 45px);
    gap: 6px;
  }

  @include media(ipad-pro) {
    grid-template-columns: repeat(4, 50px);
    gap: 8px;
  }

  @include media(ipad) {
    grid-template-columns: repeat(4, 54px);
  }
}

pirexpo-filter-expansion-panel + pirexpo-filter-expansion-panel {
  .filter-expansion-panel {
    &::before {
      display: none;
    }
  }
}

.filters-btn {
  display: none;

  @include media(ipad-pro) {
    display: block;
  }
}
